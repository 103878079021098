<template>
  <page-title-component title="Contact Us"></page-title-component>

  <div class="row">
    <div class="col-12">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-body">
              <h4 class="text-black">Need help?</h4>
              <h4 class="text-black">Found a bug?</h4>
              <h4 class="text-black">Want to request a feature?</h4>
              <p>
                Contact us at
                <a class="text-info"
                   href="mailto:support@attribution-insights.com">support@attribution-insights.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
export default {
  components: {PageTitleComponent}
}
</script>