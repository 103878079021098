<template>
  <a class="ml-2 dropdown">
    <a href="javascript:void(0);"
       class="text-muted"
       @click="updateLabels"
       id="labelDropdown"
       data-toggle="dropdown"
       aria-haspopup="true" aria-expanded="false">
      <i class="fas fa-tag"></i>
    </a>
    <div class="dropdown-menu position-absolute"
         aria-labelledby="labelDropdown">
      <div style="max-height: 200px; overflow: scroll">
        <div class="dropdown-item"
             style="padding: 0 1rem; line-height: inherit"
             v-for="label in form.labels"
             :key="'label_'+label.id">
          <input type="checkbox"
                 @change="label.indeterminate = false"
                 :id="'label_'+label.id"
                 v-model="label.checked"
                 :checked="label.checked"> {{ label.name }}
        </div>
      </div>


      <hr class="m-0">

      <div class="dropdown-item">
        <button class="btn btn-secondary btn-block btn-sm"
                @click="$refs.createLabelModal.show()">Create New</button>
        <button class="btn btn-primary btn-block btn-sm"
                @click="syncLabels">Apply</button>
      </div>
    </div>
  </a>

  <create-label-modal @added="addNewLabels" ref="createLabelModal" />
</template>

<script>
import {mapGetters} from "vuex";
import CreateLabelModal from "@/components/dashboard/CreateLabelModal.vue";

export default {
  props: ['selectedCampaignIds', 'campaigns'],
  components: {CreateLabelModal},
  data() {
    return {
      form: {
        labels: []
      }
    }
  },
  computed: {
    ...mapGetters({
      labels: 'getLabels'
    })
  },
  methods: {
    updateLabels() {
      this.form.labels = [];
      let selectedCampaigns = this.campaigns.filter(cam => this.selectedCampaignIds.includes(cam.id));

      this.labels.forEach(label => {
        const campaignHasLabel = selectedCampaigns.filter(cam => cam.labels && cam.labels.find(l => l.name === label.name));

        this.form.labels.push({
          id: label.id,
          name: label.name,
          checked: campaignHasLabel.length === selectedCampaigns.length,
          indeterminate: campaignHasLabel.length && campaignHasLabel.length !== selectedCampaigns.length,
        });

        setTimeout(() => {
          document.getElementById('label_'+label.id).indeterminate = false;

          if (campaignHasLabel.length && campaignHasLabel.length !== selectedCampaigns.length) {
            document.getElementById('label_'+label.id).indeterminate = true;
          }
        }, 10)
      })
    },
    addNewLabels(label) {
      this.axios.post('/labels/add', {
        campaign_ids: this.selectedCampaignIds,
        label: label
      }).then(res => {
        let updatedCampaigns = res.data.data;

        this.updateCampaigns(updatedCampaigns);
      })
    },
    syncLabels() {
      this.axios.post('/labels/sync', {
        campaign_ids: this.selectedCampaignIds,
        labels: this.form.labels
      }).then(res => {
        let updatedCampaigns = res.data.data;

        this.updateCampaigns(updatedCampaigns);
      })
    },
    updateCampaigns(updatedCampaigns) {
      updatedCampaigns.forEach(cam => {
        let campaign = this.campaigns.find(c => c.id === cam.id);

        if (campaign) {
          campaign.labels = cam.labels;
        }
      })
    }
  }
}
</script>