<template>
  <page-title-component title="Settings"></page-title-component>
  <div id="amazon-root"></div>

  <div class="row">
    <div class="col-lg-6 col-md-6 layout-spacing" v-if="!isCurrentPlanLoading">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-title">
              <div class="p-caption">
                <span class="caption-subject text-uppercase">Connect</span>
              </div>
            </div>
            <div class="portlet-body py-4">
              <div class="d-grid gap-2">
                <template v-if="account && account.role === 1">
                  <button type="button"
                          v-if="settings && settings.amazon_user_id"
                          @click.prevent="socialLogout('amazon')"
                          class="social-btn amazon logout">
                    <div class="social-btn-icon">
                      <i class="fab fa-amazon me-2"></i>
                    </div>
                    Logout {{ settings.amazon_name }}
                  </button>
                  <a :href="amazonAuthUrl"
                     v-else
                     class="social-btn amazon connect">
                    <div class="social-btn-icon">
                      <i class="fab fa-amazon me-2"></i>
                    </div>
                    Connect To Amazon
                  </a>

                  <twitter-button @socialLogout="socialLogout" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6" v-if="currentPlan && account && account.role === 1">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic" v-if="!currentPlan.license">
            <div class="portlet-title">
              <div class="row">
                <div class="col-6">
                  <div class="p-caption">
                    <span class="caption-subject text-uppercase"> Plan</span>
                  </div>
                </div>
                <div class="col-6">
                </div>
              </div>
            </div>
            <div class="portlet-body">
              <button v-if="loading"
                      class="btn btn-primary"
                      type="button" disabled="">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>

              <template v-else>
                <template v-if="currentPlan">
                  <div class="alert alert-danger mt-2 d-flex justify-content-between"
                       v-if="currentPlan.past_due"
                       role="alert">
                    <strong><i class="fas fa-exclamation-triangle"></i> Please Pay the due bills.</strong>

                    <a :href="currentPlan.past_due_invoice_url"
                       target="_blank"
                       class="btn btn-info">Pay Now</a>
                  </div>

                  <h4>{{ currentPlan.name }} (<span class="text-dark">${{ currentPlan.price }} /
                            <span v-if="currentPlan.recurring_interval_count > 1">{{ currentPlan.recurring_interval_count }}</span>
                            {{ currentPlan.recurring_interval }}</span>) <span class="text-success" v-if="currentPlan.promo">- {{ currentPlan.promo }}</span>
                  </h4>

                  <h5 class="text-danger" v-if="currentPlan.trial">Trial Expire at {{ currentPlan.trial_end }}</h5>
                  <p class="text-dark">Signed up at {{ currentPlan.signed_up_at }}</p>
                  <p class="text-dark">{{ currentPlan.next_billing }}</p>

                  <div v-if="defaultPaymentMethod && defaultPaymentMethod.card">
                    <img style="height: 50px" :src="'/images/'+defaultPaymentMethod.card.brand+'.png'"> **** **** **** {{ defaultPaymentMethod.card.last4 }}
                  </div>
                </template>

                <a href="#"
                   @click.prevent="$refs.billingDetailsModal.show()"
                   class="text-info font-weight-bold"><i class="fas fa-money-bill"></i> Billing Details</a>

                <div class="d-flex mt-3">
                  <button v-if="currentPlan" @click="$refs.addCardModal.show()"
                          class="btn btn-primary w-50 mr-2">
                    <i class="fas fa-credit-card"></i> Change Card
                  </button>

                  <button v-if="currentPlan" @click="$refs.applyPromoModal.show()"
                          class="btn btn-success w-50">
                    <i class="fas fa-percentage"></i> Apply Promo Code
                  </button>
                </div>
              </template>
            </div>
          </div>
          <div class="portlet portlet-basic" v-else>
            <div class="portlet-title">
              <div class="row">
                <div class="col-6">
                  <div class="p-caption">
                    <span class="caption-subject text-uppercase"> Plan</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="portlet-body">
              <button v-if="loading"
                      class="btn btn-primary"
                      type="button" disabled="">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>

              <template v-else>
                <h4>{{ currentPlan.agency }} (<span class="text-dark">{{ currentPlan.license_type }}</span>)
                  <span class="text-success">- {{ currentPlan.license }}</span>
                </h4>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <add-card-modal ref="addCardModal" />
  <apply-promo-modal ref="applyPromoModal" />
  <billing-details-modal ref="billingDetailsModal" />
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import {mapGetters} from "vuex";
import settingsMixins from "@/mixins/settingsMixins";
import TwitterButton from "@/components/settings/TwitterButton.vue";
import AddCardModal from "./modals/AddCardModal";
import BillingDetailsModal from "./modals/BillingDetailsModal.vue";
import ApplyPromoModal from "./modals/ApplyPromoModal";

export default {
  components: {PageTitleComponent, TwitterButton, AddCardModal, BillingDetailsModal, ApplyPromoModal},
  mixins: [settingsMixins],
  data() {
    return {
      amazonAuthUrl: 'https://www.amazon.com/ap/oa?client_id='+process.env.VUE_APP_AMAZON_CLIENT_ID+'&scope=profile%20advertising::test:create_account%20advertising::campaign_management&response_type=code&redirect_uri=' + process.env.VUE_APP_AMAZON_REDIRECT_URI + '&state=amazon',
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      currentPlan: 'plan/getCurrentPlan',
      features: 'auth/getFeatures',
      account: 'auth/getAccount',
      defaultPaymentMethod: 'plan/getDefaultPaymentMethod',
      isCurrentPlanLoading: 'plan/getIsLoadingCurrentPlan'
    }),
  },
  created() {
    // Amazon Auth Check
    if ("state" in this.$route.query && this.$route.query.state === 'amazon') {
      this.saveSettings({
        amazon_code: this.$route.query.code,
        platform: 'x'
      })
    }
  },
  methods: {
    socialLogout(service) {
      this.axios.post('/settings/logout/'+service)
          .then(response => {
            this.$store.commit('settings/setSettings', response.data.data);
          });
    },
    checkStatus() {
      if (this.settings.amazon_user_id)
        this.$router.push({name: 'dashboard'});
    },
  }
}
</script>

<style scoped>
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}
.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}
.social-btn.facebook.logout,
.social-btn.amazon.logout {
  background: #9ba7ca;
}

a.social-btn:hover{
  color: #fff;
}
</style>