<template>
  <div class="modal fade" id="modal-apply-promo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modal-apply-promoLabel">Enter Promo Code</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="modal-body text-center">
                  <input class="form-control"
                        v-model="form.code"
                        placeholder="Promo Code" type="text">
                </div>
              </div>
                <div class="modal-footer">
                  <button class="btn btn-primary btn-rounded my-2"
                          :disabled="loading"
                          @click="applyCode">Apply
                  </button>
                  <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
                </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      form: {
        code: ''
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-apply-promo'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    applyCode() {
      this.loading = true;

      this.axios.post('/apply-promo', this.form)
        .then((response) => {
          this.hide();
          this.showSuccessMsg("Promo Applied!")
          this.$store.commit('settings/setSettings', response.data.data);
        })
        .catch((err) => {
          this.showFailMsg(err.response.data.message);
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>