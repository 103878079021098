<template>
  <h2 class="text-black text-center">Reset Password</h2>
  <hr>
  <div class="form-group mb-3">
    <input type="password"
           class="form-control"
           v-model="resetPasswordForm.password"
           :class="{'is-invalid': errorFor('password')}"
           placeholder="Password" />

    <v-errors :errors="errorFor('password')"></v-errors>
  </div>

  <div class="form-group mb-3">
    <input type="password"
           v-model="resetPasswordForm.password_confirmation"
           :class="{'is-invalid': errorFor('password_confirmation')}"
           class="form-control"
           placeholder="Confirm Password" />

    <v-errors v-if="!showVerifyEmailMsg" :errors="errorFor('password_confirmation')"></v-errors>
  </div>

  <button @click.prevent="resetPassword"
          :disabled="loading"
          class="btn btn-block text-white font-weight-bold"
          style="background-color: #23AFB7">Reset Password</button>

  <div class="text-center">
    <p class="mt-3">Already Have an Account? <router-link :to="{name: 'login'}">Login </router-link> here!</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      resetPasswordForm: {
        password: '',
        email: this.$route.query.email,
        password_confirmation: '',
        token: this.$route.query.token
      }
    }
  },
  methods: {
    resetPassword() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/password/reset', this.resetPasswordForm)
          .then(() => {
            this.$router.push({name: 'login', query: {reset: 1}})
          })
          .catch((err) => this.allErrors = err.response.data.errors)
          .finally(() => this.loading = false);
    },
  }
}
</script>