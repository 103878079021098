<template>
  <div class="modal fade" id="modal-table-settings" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Settings</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-sm table- table-bordered">
            <tr>
              <th>Column</th>
              <th></th>
            </tr>
            <tr v-for="(column, i) in columns" :key="'column_'+i">
              <td>{{ column.name }}</td>
              <td class="text-center">
                <input type="checkbox"
                       @change="saveSettings"
                       v-model="column.show">
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modal: null
    }
  },
  computed: {
    ...mapGetters({
      columns: 'settings/getDashboardColumns'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-table-settings'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    saveSettings() {
      this.$store.dispatch('settings/saveSettings', {
        'dashboard_columns': this.columns
      });
    }
  }
}
</script>