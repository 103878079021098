<template>
  <div class="modal fade" id="billing-details-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-apply-promoLabel">Billing Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-hover">
            <thead>
            <tr>
              <th>Date</th>
              <th>Invoice Number</th>
              <th>Service Period</th>
              <th>Total</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(invoice, i) in invoices" :key="'invoice_'+i">
              <td>
                <a :href="invoice.pdf"
                   target="_blank"
                   class="text-info">{{ invoice.created }}</a>
              </td>
              <td>{{ invoice.number }}</td>
              <td>{{ invoice.service_period }}</td>
              <td>{{ invoice.total }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      invoices: []
    }
  },
  created() {
    this.loadInvoices();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('billing-details-modal'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadInvoices() {
      this.invoices = [];

      this.axios.get('/invoices')
        .then(res => {
          this.invoices = res.data.data;
        })
    }
  }
}
</script>