<template>
  <h2 class="text-black text-center">Reset Password</h2>
  <hr>
  <div class="form-group mb-3">
    <input type="text"
           class="form-control"
           v-model="resetForm.email"
           :class="{'is-invalid': errorFor('email')}"
           placeholder="Email" />

    <v-errors :errors="errorFor('email')"></v-errors>
  </div>

  <button @click.prevent="resetPassword"
          :disabled="loading"
          class="btn btn-block text-white font-weight-bold"
          style="background-color: #23AFB7">Send Reset Link</button>

  <div class="text-center">
    <p class="mt-3">Already Have an Account? <router-link :to="{name: 'login'}">Login </router-link> here!</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      resetForm: {
        email: ''
      },
    }
  },
  methods: {
    resetPassword() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/forgot-password', this.resetForm)
          .then(() => {
            this.resetForm.email = '';
            this.showSuccessMsg("Reset Link Sent!")
          })
          .catch((err) => this.allErrors = err.response.data.errors)
          .finally(() => this.loading = false);
    },
  }
}
</script>

<style scoped>
@import "../../assets/css/auth.css";
</style>