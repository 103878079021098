<template>
  <h1>Please wait...</h1>
</template>

<script>
export default {
  beforeCreate() {
    this.axios.post('/check-invitation', {
        code: this.$route.query.invitation
      }).then(() => {
          window.location.href = process.env.VUE_APP_BASE
        })
      .catch((err) => {
        this.showFailMsg(err.response.data.message);
        this.$router.push({name: 'settings'})
      });
  }
}
</script>