<template>
  <div class="modal fade" id="modal-add-card" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Card Information</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div id="card-element"></div>
          <span class="text-danger" v-if="cardError.show">{{ cardError.message }}</span>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success btn-sm"
                  :disabled="loading"
                  @click="addCard">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      addPaymentView: null,
      cardError: {
        message: null,
        show: false
      },
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-add-card'))
  },
  methods: {
    show() {
      this.getPaymentView();
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    getPaymentView() {
      this.addPaymentView = null;
      this.axios.get('/add-payment-view')
        .then(res => {
          this.addPaymentView = res.data

          setTimeout(() =>{
            this.initStripe();
          }, 50)
        });
    },
    async addCard() {
      this.cardError.show = false;
      this.loading = true;

      const data = await this.stripeInit.createPaymentMethod(
        'card', this.paymentForm
      );

      if (data.error) {
        this.cardError.message = data.error.message
        this.cardError.show = true
      } else {
        this.cardError.message = null
        this.cardError.show = false
      }

      if (!data.error) {
        this.axios.post('/update-payment', {
          paymentId: data.paymentMethod.id
        }).then(() => {
          this.hide();
          this.$store.dispatch('plan/getCurrentPlan')
          this.$store.dispatch('plan/getDefaultPaymentMethod')
        }).finally(() => this.loading = false);
      }
    },
    initStripe() {

      this.stripeInit = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

      const elements = this.stripeInit.elements();
      this.paymentForm = elements.create('card');
      this.paymentForm.mount('#card-element');


      /*let self = this;
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

      const elements = stripe.elements();
      const cardElement = elements.create('card');

      cardElement.mount('#card-element');

      const cardHolderName = document.getElementById('card-holder-name');
      const cardButton = document.getElementById('card-button');

      cardButton.addEventListener('click', async () => {
        cardButton.setAttribute("disabled", "disabled");

        stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: cardHolderName,
          },
        })
          .then(function(result) {
            if (result.error) {
              cardButton.setAttribute("disabled", "")
              self.showFailMsg(result.error.message)
            } else {
              self.axios.post('/update-payment', {
                paymentId: result.paymentMethod.id
              })
                .then(() => {
                  self.hide();
                  self.$store.dispatch('plan/getCurrentPlan')
                  self.$store.dispatch('plan/getDefaultPaymentMethod')
                })
                .finally(() => cardButton.setAttribute("disabled", ""))
            }
          });
      });*/
    },
  }
}
</script>


<style>
#card-element {
    padding: 10px 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#card-button {
    width: 100%;
    border-radius: 1.875rem !important;
}
</style>