<template>
  <div class="modal fade"
       :id="id"
       tabindex="-1"
       role="dialog"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-google-campaign-detailsLabel">Campaign Attributes</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="loading"
               class="d-flex items-center justify-content-center w-100 py-5">
            <div class="spinner-border spinner-border-sm mr-2"></div>
            {{ loadingMessage }}
          </div>
          <div class="statbox widget box p-0" v-else-if="!loading && campaign">
            <div class="widget-content widget-content-area p-0 box-shadow-none">
              <div class="table-responsive">
                <table class="table table-bordered mb-0">
                  <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{{ campaign.id }}</td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{{ campaign.name }}</td>
                  </tr>
                  <tr>
                    <th>Budget Optimization</th>
                    <td>{{ campaign.budget_optimization }}</td>
                  </tr>
                  <tr>
                    <th>Bid Strategy</th>
                    <td>{{ campaign.line_items[0]?.bid_strategy }}</td>
                  </tr>
                  <tr>
                    <th>Objective</th>
                    <td>{{ campaign.line_items[0]?.objective }}</td>
                  </tr>
                  <tr>
                    <th>Daily Budget</th>
                    <td>
                      <div class="row" v-if="!editBudget">
                        <div class="col-md-10">
                          {{ campaign.daily_budget_amount_local_micro ? (campaign.currency + ' ' + campaign.daily_budget_amount_local_micro / 1000000) : 'N/A' }}
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editBudget = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <div class="form-group mb-0">
                            <input :class="['form-control form-control-sm', {'is-invalid': errorFor('amount')}]"
                                   :disabled="loading"
                                   type="text"
                                   placeholder="Enter New Daily Budget"
                                   v-model="newBudgetForm.amount">
                            <v-errors :errors="errorFor('amount')" />
                          </div>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editBudget = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveBudget">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Start Time</th>
                    <td>{{ formatDate(campaign.line_items[0]?.start_time) }}</td>
                  </tr>
                  <tr>
                    <th>End Time</th>
                    <td>{{ formatDate(campaign.line_items[0]?.end_time) }}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <div class="row" v-if="!editStatus">
                        <div class="col-md-10">
                          {{ campaign.entity_status }}
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editStatus = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <div class="form-group mb-0">
                            <select :class="['form-control form-control-sm']"
                                    :disabled="loading"
                                    v-model="newStatusForm.status">
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="PAUSED">PAUSED</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editStatus = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveStatus">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['id'],
  data() {
    return {
      loadingMessage: '',
      loading: false,
      campaign: null,
      newBudgetForm: {
        amount: '',
        accountId: '',
        campaignId: ''
      },
      newStatusForm: {
        status: '',
        accountId: '',
        campaignId: ''
      },
      editBudget: false,
      editStatus: false,
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById(this.id));
  },
  methods: {
    show({twitter_account_id, twitter_campaign_id}) {
      this.loadCampaign(twitter_account_id, twitter_campaign_id);

      this.newBudgetForm.campaignId = twitter_campaign_id;
      this.newBudgetForm.accountId = twitter_account_id;

      this.newStatusForm.campaignId = twitter_campaign_id;
      this.newStatusForm.accountId = twitter_account_id;


      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    formatDate(date) {
      return date ? window.moment(date).format('MMMM Do YYYY, h:mm:ss a') : 'N/A';
    },
    loadCampaign(twitter_account_id, twitter_campaign_id) {
      this.campaign = null;
      this.loading = true;
      this.loadingMessage = "Reaching out to X...";

      this.axios.get(`/twitter/campaigns/${twitter_account_id}/${twitter_campaign_id}`)
          .then(response => {
            this.campaign = response.data.data;
            this.loading = false;

            if (response.data.data.entity_status !== 'DRAFT') {
              this.newStatusForm.status = response.data.data.entity_status;
            } else {
              this.newStatusForm.status = 'PAUSED';
            }
          })
          .catch(() => {});
    },
    saveBudget() {
      this.allErrors = null;
      this.loading = true;
      this.loadingMessage = "Updating budget...";

      this.axios.post('/twitter/change/budget', this.newBudgetForm)
          .then(() => {

            this.campaign.daily_budget_amount_local_micro = parseFloat(this.newBudgetForm.amount) * 1000000;
            this.newBudgetForm.amount = '';
            this.editBudget = false;
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    saveStatus() {
      this.allErrors = null;
      this.loading = true;
      this.loadingMessage = "Updating status...";

      this.axios.post('/twitter/change/status', this.newStatusForm)
          .then(() => {
            this.campaign.entity_status = this.newStatusForm.status;
            this.editStatus = false;
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
}
</script>