<template>
  <page-title-component title="Change Password"></page-title-component>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Current Password <span class="text-danger">*</span></label>
                <input type="password"
                       v-model="form.current_password"
                       :class="['form-control', {'is-invalid': errorFor('current_password')}]">

                <v-errors :errors="errorFor('current_password')" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>New Password <span class="text-danger">*</span></label>
                <input type="password"
                       v-model="form.new_password"
                       :class="['form-control', {'is-invalid': errorFor('new_password')}]">

                <v-errors :errors="errorFor('new_password')" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Confirm New Password <span class="text-danger">*</span></label>
                <input type="password"
                       v-model="form.new_password_confirmation"
                       :class="['form-control', {'is-invalid': errorFor('new_password_confirmation')}]">

                <v-errors :errors="errorFor('new_password_confirmation')" />
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button type="submit"
                      class="btn btn-success mr-1"
                      @click.prevent="submit"
                      :disabled="loading">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent";
export default {
  components: {PageTitleComponent},
  data() {
    return {
      title: 'Change Password',
      form: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      }
    }
  },
  methods: {
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/change-password', this.form)
        .then(() => {
          this.form.current_password = '';
          this.form.new_password = '';
          this.form.new_password_confirmation = '';

          this.showSuccessMsg('Password Changed Successfully!')
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>