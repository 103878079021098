<template>
  <div class="modal fade" id="modal-create-label" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Create Label</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input class="form-control"
                 v-model="form.name"
                 placeholder="Label">
        </div>
        <div class="modal-footer">
          <button class="btn btn-success btn-sm"
                  @click="save"
                  :disabled="loading || !form.name">Create</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      form: {
        name: ''
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-create-label'))
  },
  methods: {
    show() {
      this.form.name = '';

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    save() {
      this.loading = true;

      this.axios.post('/labels', this.form)
          .then(() => {
            this.hide();
            this.$store.dispatch('loadLabels');
            this.$emit('added', this.form.name);
          })
    }
  }
}
</script>