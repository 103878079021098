<template>
  <h2 class="text-black text-center">Login</h2>
  <hr>
  <div class="form-group mb-3">
    <input type="text"
           class="form-control"
           v-model="loginForm.email"
           :class="{'is-invalid': errorFor('email')}"
           placeholder="Email" />

    <v-errors v-if="!showVerifyEmailMsg" :errors="errorFor('email')"></v-errors>
  </div>

  <div class="form-group mb-3">
    <input type="password"
           v-model="loginForm.password"
           :class="{'is-invalid': errorFor('password')}"
           class="form-control"
           placeholder="Password" />

    <v-errors v-if="!showVerifyEmailMsg" :errors="errorFor('password')"></v-errors>
  </div>

  <div class="text-danger mb-3" v-if="showVerifyEmailMsg">
    Email Not Verified. <a href="#"
                           class="text-info bold"
                           @click="resendVerificationEmail">Resend Verification Link</a>
  </div>

  <div class="d-flex mb-3 align-items-center">
    <span class="ml-auto">
      <router-link :to="{name: 'forget_password'}"
                   class="forgot-pass">Forgot Password</router-link>
    </span>
  </div>

  <input value="Log In"
         @click.prevent="login"
         :disabled="loading"
         class="btn btn-block text-white font-weight-bold"
         style="background-color: #23AFB7">

  <div class="text-center">
    <p class="mt-3">New Here? <router-link :to="{name: 'signup'}">Sign Up </router-link>a new user!</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      showVerifyEmailMsg: false,
      loginForm: {
        email: '',
        password: '',
        invitation: this.$route.query.invitation,
        device: 'web'
      },
    }
  },
  methods: {
    login() {
      this.allErrors = null;
      this.loading = true;

      this.$store.dispatch('auth/login', this.loginForm)
          .then(() => {
            window.location.href = process.env.VUE_APP_BASE
            if (this.$route.query.invitation) {
              window.location.href = process.env.VUE_APP_BASE + 'invitation'
            } else if (this.$route.query.accountUser) {
              window.location.href = process.env.VUE_APP_BASE + '?accountUser=' + this.$route.query.accountUser;
            } else {
              window.location.href = process.env.VUE_APP_BASE
            }
          })
          .catch((err) => {
            this.allErrors = err.data.errors
          })
          .finally(() => this.loading = false);
    },
  }
}
</script>

<style scoped>
@import "../../assets/css/auth.css";
</style>