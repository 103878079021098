<template>
  <div class="modal fade"
       id="twitter-ads-preview-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-google-campaign-detailsLabel">Preview</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="height: 650px">
          <div v-html="iframeUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iframeUrl: '',
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('twitter-ads-preview-modal'));
  },
  methods: {
    show(iframeUrl) {
      this.iframeUrl = iframeUrl;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    }
  }
}
</script>

<style>
.tweet-preview {
  height: 580px;
  width: 100%;
  border: none;
}
</style>